.home-main-text {
    font-size: 6vh;
    color: white;
    text-align: center;
}

.home-button-collab {
    font-size: 3rem;
    color: white;
    text-align: center;
}
