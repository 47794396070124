// the tag container
.input-group div {
    background-color: white;
}


// the tag itself
.input-group div div {
    background-color: #3D43C7 !important;
}
