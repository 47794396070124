.overlay-container {
    position: absolute;
    z-index:10;
    padding: 16px !important;
    border-radius: 5vw;
    background-color: #7374C9;

    top: 50vmin;
    min-width: 300px;
}

.overlay-card {
    position: relative;
    border-radius: 5vw !important;
}
