.subscribers-filter {
    top: 5rem;
    left: 5rem;
    position: absolute
}

.subscribers-filter-form {
    padding-left: 16px;
            background-color: #f8f9fa;
            border-radius: 40px
}

.subscribers-filter-radio input {
    background-color: #f8f9fa;
}

.overlay-container-menu {
    right: 16px;
    top: 7rem;
    width: 25vw !important;
    min-width: 300px;
}

.overlay-container-collab-note {
    left: 16px;
    top: 15rem;
    width: 40% !important;
  }

.overlay-welcome-message {
    top: 30%;
    left: 15%;
    width: 70% !important;
    opacity: 0.8;
}
