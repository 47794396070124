@font-face {
  font-family:  'Luckiest Guy';
  src: local('Luckiest Guy'), url(./fonts/LuckiestGuy/LuckiestGuy-Regular.ttf) format('truetype');
}

@import "custom";

body {
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-luckiest-guy {
  font-family: 'Luckiest Guy';
}

.font-inter {
  font-family: 'Inter';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  text-align: center;
}

.navbar {
  height: $navbar-height;
  padding-top:0;
  padding-bottom:0;
}

.navbar-brand {
  padding-top:0;
}

.navbar-brand-img {
  height: $navbar-height;
  padding: 8px;
}

.offcanvas-body {
  background-color: $secondary;
}

.offcanvas-header{
  background-color: $primary;
}

.Main-footer {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $light;
}

.rounded-40 {
  border-radius: 40px;
  padding: 20px;
}

.round-icon {
  border-radius: 100%;
  padding:20px;
  background: $secondary
}

.overlay-bottom-center {
  position: absolute;
  bottom: 5vh;
  width: auto;
}

.button-outlined {
  padding:20px;
  border-radius: 5rem;
  background-color: transparent;
  color: white;
}

.close-button {
  right: 1vmin;
  top: -2.5rem;
  position: absolute;
  border-radius: 50%;
  background-color: $primary;
}

.youtube_button {
  color: red;
  font-size: 4rem;
}

.width-auto {
  width: auto !important;
}
