.map {
    height: calc(100vh - 4rem);
    width: 100%;
    z-index: 0;
}

.marker-cluster-small {
    background-color: rgba(61, 67, 199, 0.6)
}

.marker-cluster-small div {
    background-color: rgba(61, 67, 199, 0.4);
    color: #f8f9fa;
}

.marker-cluster-medium {
    background-color: rgba(61, 67, 199, 0.6)
}

.marker-cluster-medium div {
    background-color: rgba(61, 67, 199, 0.6);
    color: #f8f9fa;
}

.marker-cluster-large {
    background-color: rgba(61, 67, 199, 0.6)
}

.marker-cluster-large div {
    background-color: rgba(61, 67, 199, 1);
    color: #f8f9fa;
}
