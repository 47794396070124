$theme-colors: (
  "primary":    #3D43C7,
  "secondary":  #f8f9fa,
  "success":    #198754,
  "info":       #0dcaf0,
  "warning":    #ffc107,
  "danger":     #dc3545,
  "light":      #f8f9fa,
  "dark":       #212529
) ;

$primary: #3D43C7;
$primary-light: #7374C9;
$secondary: #f8f9fa;
$light: #f8f9fa;

$body-bg: #000;
$link-color: #0d6efd;

$navbar-height: 4rem;

$font-family-base: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$font-weight-base: 400;
$font-size-base: 1rem;
$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;



/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";
